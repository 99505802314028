import React, { Suspense, lazy } from "react";
import NavbarTopDashboard from "../components/Navbar/NavbarTop";
import { useSidebarContext } from "src/context/SidebarContext";
import clsx from "clsx";
const NavbarSideDashboard = lazy(() =>
  import("../components/Navbar/NavbarSide")
);

function MainLayout({ color, children }) {
  const { isOpen } = useSidebarContext();
  return (
    <div className={color}>
      <NavbarTopDashboard />
      <div className="flex mx-auto">
        <Suspense
          fallback={
            <div className="border-b w-4 h-4 m-auto rounded-full border-primary  animate-spin" />
          }
        >
          <NavbarSideDashboard />
        </Suspense>
        <div className={clsx("p-4 sm:p-6 container mx-auto w-full md:w-[calc(100%-320px)]",
          isOpen ? 'max-h-[200px] overflow-y-hidden md:max-h-max' : '')}>{children}</div>
      </div>
    </div>
  );
}

export default MainLayout;
