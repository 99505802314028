import axios from "axios";
import moment from "moment";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useUserAuth } from "./UserAuthContext";


type EarningsContextProps = {
  earnings: {
    status: string
    data: any[]
  },
  monthlyYearlyEarnings: number,
  periodearnings: number,
  lastMonthEarnings: number,
  totalEarnings: number,
  actualEarnings: number,
  earningDetails: any,
  salelist: any[],
  salesDetails: (params: any) => void,
  loading: boolean,
}



const EarningsContext = createContext(undefined as any as EarningsContextProps);
export function EarningsContextProvider({ children }) {
  const [monthlyYearlyEarnings, setMonthlyYearlyEarnings] = useState(0);
  const [lastMonthEarnings, setlastMonthEarnings] = useState(0);
  const [periodearnings, setperiodearnings] = useState(0);
  const [salelist, setSalesList] = useState([]);
  const [earnings, setEarnings] = useState({ status: "init", data: [] });
  const { userProfile } = useUserAuth()
  const [loading, setLoading] = useState(false)

  const fetchEarning = useCallback(async (params) => {
    if (!params?.user_id)
      return { status: "error", data: [] }
    try {
      setLoading(true);
      const { data: response } = await axios.get("/api/affliate/earnings", {
        params: {
          ...params,
          requestName: 'GET EARNING'
        }
      })
      setLoading(false);
      return { status: 'success', data: response.data }
    } catch (error) {
      setLoading(false);
      return { status: "error", data: [] }
    }
  }, [])

  const fetchEarningsRange = async (params) => {
    try {
      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth() + 1; // getMonth() returns 0-11

      const lastMonth = currentMonth === 1 ? 12 : currentMonth - 1;
      const lastMonthYear = currentMonth === 1 ? currentYear - 1 : currentYear;

      const currentMonthParams = {
        start_date: `${currentYear}-${String(currentMonth).padStart(2, '0')}-01`,
        end_date: `${currentYear}-${String(currentMonth).padStart(2, '0')}-${new Date(currentYear, currentMonth, 0).getDate()}`,
        ...params // other params
      };

      const lastMonthParams = {
        start_date: `${lastMonthYear}-${String(lastMonth).padStart(2, '0')}-01`,
        end_date: `${lastMonthYear}-${String(lastMonth).padStart(2, '0')}-${new Date(lastMonthYear, lastMonth, 0).getDate()}`,
        ...params // other params
      };

      const [currentMonthResponse, lastMonthResponse] = await Promise.all([
        axios.get("/api/affliate/earnings", { params: currentMonthParams }),
        axios.get("/api/affliate/earnings", { params: lastMonthParams })
      ]);


      const currentMonthEarnings = currentMonthResponse.data.data.reduce((acc, item) => acc + item.amount, 0);
      const lastMonthEarnings = lastMonthResponse.data.data.reduce((acc, item) => acc + item.amount, 0);
      setMonthlyYearlyEarnings(currentMonthEarnings.toFixed(2));
      setlastMonthEarnings(lastMonthEarnings.toFixed(2));
    } catch (error) {
      console.error('Error fetching earnings data', error);
    }
  };

  const earningDetails = useCallback(async (params) => {
    setEarnings({ status: "init", data: [] });
    setEarnings(await fetchEarning(params));
  }, [])

  useEffect(() => {
    if (userProfile?._id) {
      earningDetails({ user_id: userProfile?._id })
      fetchEarningsRange({ user_id: userProfile?._id })
    }
  }, [userProfile?._id])

    // function earningDetails(params, type) {
    //   axios
    //     .get("/api/affliate/earnings", { params })
    //     .then((res) => {
    //       if (type === "monthly") {
    //         setMonthlyYearlyEarnings(
    //           res.data.data["approved_amount"] + res.data.data["pending_amount"]
    //         );
    //       } else if (type === "lastmonth") {
    //         setlastMonthEarnings(
    //           res.data.data["approved_amount"] + res.data.data["pending_amount"]
    //         );
    //       } else if (type === "day" || type === "yearly") {
    //         setperiodearnings(
    //           res.data.data["approved_amount"] + res.data.data["pending_amount"]
    //         );
    //       } else {
    //         settotalEarnings(
    //           res.data.data["approved_amount"] + res.data.data["pending_amount"]
    //         );
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //     });
    // }

  function salesDetails(params) {
    axios
      .get("/api/affliate/sales", {
        params: {
          ...params, requestName: 'LIST SALES DETAILS'
        }
      })
      .then((res) => {
        let resp = changeGraphStructure(
          res.data.data,
          params.start_date,
          params.end_date
        );
        setSalesList(resp as any);
        return resp;
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function changeGraphStructure(data, startDate, endDate) {
    startDate = moment(startDate);
    endDate = moment(endDate);
    const totalDays = moment.duration(endDate.diff(startDate)).asDays() + 1;
    const saledata = totalsalebydate(totalDays, startDate, data);
    return saledata;
  }
  function totalsalebydate(totalDays, startDate, data) {
    if (totalDays !== 365) {
      let addDataByDays = {};
      addDataByDays["data"] = [];
      addDataByDays["labels"] = [];
      var new_date = startDate;

      for (let i = 0; i < totalDays;) {
        if (i > 0) {
          new_date = moment(startDate, "YYYY-MM-DD").add(i, "days");
        }
        let totalperc = 0;
        for (let j = 0; j < data.length;) {
          var sale_date = moment(
            moment(data[j].created_at).format("YYYY-MM-DD"),
            "YYYY-MM-DD"
          );

          if (sale_date.diff(moment(new_date.format("YYYY-MM-DD"))) === 0) {
            totalperc = data[j].comission + totalperc;
          } else {
            totalperc = totalperc + 0;
          }
          j++;
        }

        if (i === 0) {
          addDataByDays["labels"].push(startDate.format("YYYY-MM-DD"));
          addDataByDays["data"].push(totalperc);
        } else {
          addDataByDays["labels"].push(new_date.format("YYYY-MM-DD"));
          addDataByDays["data"].push(totalperc);
        }
        i++;
      }
      return addDataByDays;
    } else {
      const months = moment.monthsShort();
    }
  }


  const totalEarnings = useMemo(() => {
    if (!earnings.data) {
      return 0;
    }

    return earnings.data.reduce((acc, item) => {
      return (Number(acc) + Number(item.amount ?? 0)).toFixed(2);
    }, 0);
  }, [earnings]);

  const actualEarnings = useMemo(() => {
    if (!earnings.data) {
      return 0;
    }

    return earnings.data.reduce((acc, item) => {
      return (Number(acc) + Number(item.status == 1 ? item?.amount || 0 : 0)).toFixed(2);
    }, 0);
  }, [earnings]);


  return (
    <EarningsContext.Provider
      value={{
        earnings,
        monthlyYearlyEarnings,
        periodearnings,
        lastMonthEarnings,
        earningDetails,
        salelist,
        salesDetails,
        totalEarnings,
        loading,
        actualEarnings,
      }}
    >
      {children}
    </EarningsContext.Provider>
  );
}

export function useEarningsContext() {
  return useContext(EarningsContext);
}
