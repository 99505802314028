import React from "react";
import Image from "../Image/Image";
import { useUserAuth } from "../../context/UserAuthContext";
import { useSiteContext } from "../../context/SiteContext";
import { Link } from "react-router-dom";
import { MenuIcon } from '@heroicons/react/solid'
import { useSidebarContext } from "../../context/SidebarContext";
import { useLogs } from "src/context/LogContext";

function NavbarTopDashboard() {
  const { addLog } = useLogs()
  const { user, logOut } = useUserAuth()
  const { site } = useSiteContext();
  let iconData = {
    src: process.env.PUBLIC_URL + "/icons/icon-affiliate.png",
    alt: "Icon",
    width: "50px",
    height: "20px",
  };
  let logoData = {
    src: site?.logo,
    alt: "Logo",
    style: "object-contain w-full h-full"
  };

  const { setIsOpen, isOpen } = useSidebarContext()

  const handleLogout = () => {
    addLog({
      action: "LOGGING OUT",
      type: "action",
      url: '/profile'
    })
    logOut();
  }


  return (
    <nav className="shadow-md bg-white sticky">
      <div className="max-w-[2500px] mx-auto px-5 py-2 flex items-center justify-between">
        <div className="h-16 md:hidden w-10 flex items-center justify-center">
          <MenuIcon onClick={() => setIsOpen(!isOpen)} className="w-6 h-6" width={24} height={24} />
        </div>
        <div className="h-16 hidden md:block max-w-[220px] w-full">
          {site?.logo ? <Image imagedata={logoData} /> :
            <div className="bg-gray-300 animate-pulse h-full w-full" />}
        </div>
        <div className="w-full">
          <ul className="md:flex hidden justify-end md:gap-8 items-center w-full gap-2">
            <button className="">
              <Link to="/support">Support</Link>
            </button>
            <li>{user?.email}</li>
            <button onClick={handleLogout} title="Log out">Log out</button>
          </ul>
          <ul className="md:hidden flex justify-end md:gap-8 items-center w-full gap-2">
            <button className="text-sm" onClick={handleLogout} title="Log out">Log out</button>
          </ul>
        </div>
      </div>
      <div className=" bg-secondary px-5 py-4 text-white">
        <div className="max-w-[2500px] mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <Image imagedata={iconData} />
            <h1 className="md:text-3xl ml-2">Affiliates Program</h1>
          </div>
          <div>
            <Link to="/terms-of-service">Terms of Service</Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavbarTopDashboard;
