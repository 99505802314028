import { createContext, useCallback, useContext, useMemo, useState } from "react"
import axios from "axios"
import { useEffect } from "react"
import { useLocation } from 'react-router-dom'
import { useUserAuth } from "./UserAuthContext"

const baseURL = process.env.REACT_APP_BASE_URL;
const authKey = process.env.REACT_APP_AUTH_KEY;
const SITE_CODE = process.env.REACT_APP_SITE_CODE;

type Log = {
  user_id?: string
  type: 'route' | 'action'
  ip?: string
  url: string
  action: string
  user_agent?: string
}

type LogContextType = {
  addLog: (log: Log) => void
  logs: Log[]
}


const LogContext = createContext<LogContextType | undefined>(undefined)

export const useLogs = () => {
  const context = useContext(LogContext)
  if (context === undefined) {
    throw new Error('useLogs must be used within a LogProvider')
  }
  return context
}

export function LogProvider(props) {
  const location = useLocation()
  const [logs, setLogs] = useState<Log[]>([]);
  const { userProfile } = useUserAuth()
  const [ip, setIP] = useState('')

  const addLog = useCallback((log: Log) => {
    if (!userProfile?._id) return;
    const newLog: Log = { ...log, user_agent: window.navigator.userAgent, ip, user_id: userProfile?._id };
    submitLog(newLog)
    setLogs(logs => [...logs, newLog])
  }, [userProfile?._id, ip])

  const submitLog = async (log: Log) => {
    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("x-AuthKey", authKey || "12345678");
    myHeaders.append("x-SiteCode", SITE_CODE);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify(log);

    const requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(baseURL + "/api/affliate/logs", requestOptions)
      .then(response => response.text())
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    const fetchIp = async () => {
      try {
        const { data } = await axios.get("https://jsonip.com", { params: { requestName: 'GET USER IP' } })
        setIP(data?.ip || 'Not Found');
      } catch (error) {
        console.error(error)
        setIP("NOT FOUND")
      }
    }

    if (!ip || ip.length === 0)
      fetchIp();
  }, [ip])

  useEffect(() => {
    if (!ip || !userProfile) return;
    addLog({ type: 'route', url: location.pathname, action: 'Visited ' + location.pathname })
  }, [location, ip])

  useEffect(() => {
    if (!ip) return;
    let interceptor;
    try {
      interceptor = axios.interceptors.request?.use((config) => {
        if (!config || config?.url?.includes('/api/affliate/logs') || !userProfile?._id) return config;
        const action = config.params?.requestName || config.method + ' ' + config.url;
        addLog({ type: 'action', url: config.url, action, })
        return config;
      })
    } catch (error) {

    }
    return () => {
      axios.interceptors.request.eject(interceptor)
    }
  }, [ip])

  const value: LogContextType = useMemo(() => {
    return {
      logs,
      addLog
    }
  }, [addLog, logs])
  return <LogContext.Provider value={value} {...props} />
}


