import { useEffect } from "react";
import { useProductContext } from "../../context/ProductContext";
import { useUserAuth } from "src/context/UserAuthContext";

const Onboard = () => {
  const { user } = useUserAuth();
  const { productDetails } = useProductContext();

  useEffect(() => {
    let ignore = false;
    if (user.uid && !ignore) {
      productDetails({
        user_id: user.uid,
      });
    }
    return () => {
      ignore = true;
    };
  }, [user.uid]);

  return "";
};

export default Onboard;
