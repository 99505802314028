//This component can be used as number, text,testarea, email, password field
import React from "react";
import { Loader } from '@mantine/core'

function Spinner() {
  return (
    <Loader size={41} variant="primary" />
  );
}
export default Spinner;
