function timegraph(data) {
  let templabels = [];
  let timelabels = [];
  let tempsaleprice = [];
  
  data.forEach((resp) => {
    if (resp.status !== 2) {
      tempsaleprice.push(resp.comission);
      let time = resp.created_at.split(" ");
      let timewithflag = time[1] + " " + time[2];
      let temptime = parseFloat(time[1].replace(/:/g, "."));
  
      templabels.push(temptime);
      timelabels.push(timewithflag);

    }
  });
  let timeobject = {};
  templabels.forEach((temptime, index1) => {
    timeobject[timelabels[index1]] = [];
    templabels.filter((timelabel, index2) => {
      if (
        temptime === timelabel &&
        timelabels[index1].split(" ")[1] === timelabels[index2].split(" ")[1]
      ) {
        timeobject[timelabels[index1]].push(index2);
      }
    });
  });
  Object.keys(timeobject).forEach(function (key) {
    let sum = 0;
    timeobject[key].forEach((indices, ind) => {
      sum += tempsaleprice[indices];
    });
    timeobject[key] = sum;
  });
  let sales = Object.values(timeobject);
  let labels = Object.keys(timeobject);
  var list = [];
  for (var j = 0; j < labels.length; j++) {
    list.push({ labels: labels[j], conversion: sales[j] });
  }

  list.sort(function (a, b) {
    return a.labels < b.labels ? -1 : a.labels === b.labels ? 0 : 1;
  });

  //3) separate them back out:
  for (var k = 0; k < list.length; k++) {
    labels[k] = list[k].labels;
    sales[k] = list[k].conversion;
  }
  // console.log(list)
  let totalSales = sales.reduce((a, b) => a + b, 0);
  return { labels: labels, sales: sales, totalsales: totalSales };
}

// function timeconversiongraph(data){
//   let templabels = [];
//   let timelabels = [];
//   let tempsaleprice = [];
//   data.forEach(resp => {
//     tempsaleprice.push(resp.comission)
//     let time = resp.created_at.split(" ");
//     let timewithflag = time[1]+" "+time[2]
//     let temptime = parseFloat(time[1].replace(/:/g,"."))

//     templabels.push(temptime);
//     timelabels.push(timewithflag);
//   })
//   let timeobject = {};
//   templabels.forEach((temptime, index1) =>{
//     timeobject[timelabels[index1]] = [];
//     templabels.filter((timelabel, index2)=>{
//       if(temptime === timelabel && (timelabels[index1].split(" ")[1] === timelabels[index2].split(" ")[1])){
//         timeobject[timelabels[index1]].push(index2);
//       }
//     })
//   });
//   Object.keys(timeobject).forEach(function(key) {
//     let sum = 0;
//     timeobject[key].forEach((indices,ind)=>{
//       sum +=tempsaleprice[indices]
//     })
//     timeobject[key] = sum;
//   });
//   console.log(timeobject);
//   let sales  = Object.values(timeobject);
//   let labels = Object.keys(timeobject);
//   let totalSales = sales.reduce((a,b) => a + b, 0);

//   return {"labels":labels,"sales":sales, "totalsales":totalSales}
// }

function daygraph(data) {
  let datelabels = [];
  let tempsaleprice = [];

  data.forEach((resp) => {
    if (resp.status !== 2) {
      tempsaleprice.push(resp.comission);
      let date = resp.created_at.split(" ");
      let dateflag = date[0];
      datelabels.push(dateflag);
    }
  });
  let dateobject = {};
  datelabels.forEach((dateflag, index) => {
    dateobject[dateflag] = [];
    datelabels.filter((datelabel, index) => {
      if (datelabel === dateflag) {
        dateobject[dateflag].push(index);
      }
    });
  });
  Object.keys(dateobject).forEach(function (key) {
    let sum = 0;
    dateobject[key].forEach((indices, ind) => {
      sum += tempsaleprice[indices];
    });
    dateobject[key] = sum;
  });
  let sales = Object.values(dateobject);
  let labels = Object.keys(dateobject);
  let totalSales = sales.reduce((a, b) => a + b, 0);

  return { labels: labels, sales: sales, totalsales: totalSales };
}
function dayconversiongraph(data) {
  let datelabels = [];
  // let trafficdatelabels = [];
  let tempconversion = [];
  let temptraffic = [];
  let tempregistration = 0;
  let tempclicks = 0;
  let tempcomission = 0;

  data.forEach((resp) => {
    tempconversion.push(resp.conversion);
    temptraffic.push(resp.visit_details.length);
    tempregistration += resp.registration;
    tempclicks += resp.clicks;
    // if (resp.conversion != 0) {
    //   tempcomission += resp.comission;
    // }
    tempcomission += resp.comission;
    let date = resp.created_at.split(" ");
    let dateflag = date[0];
    datelabels.push(dateflag);
  });
  let dateobject = {};
  let trafficobject = {};
  datelabels.forEach((dateflag, index) => {
    dateobject[dateflag] = [];
    trafficobject[dateflag] = [];
    datelabels.filter((datelabel, index) => {
      if (datelabel === dateflag) {
        dateobject[dateflag].push(index);
        trafficobject[dateflag].push(index);
      }
    });
  });
  Object.keys(dateobject).forEach(function (key) {
    let sum = 0;
    let trafficsum = 0;
    dateobject[key].forEach((indices, ind) => {
      sum += tempconversion[indices];
      trafficsum += temptraffic[indices];
    });
    if (sum !== 0) {
      dateobject[key] = sum;
    } else {
      delete dateobject[key];
    }
    trafficobject[key] = trafficsum;
  });
  let conversion = Object.values(dateobject);
  let labels = Object.keys(dateobject);
  let trafficlabels = Object.keys(trafficobject);
  let traffics = Object.values(trafficobject);
  let totalConversion = conversion.reduce((a, b) => a + b, 0);

  let final_conversion =
    (totalConversion / traffics.reduce((a, b) => a + b, 0) ?? 1) * 100;

  if (final_conversion > 100) {
    final_conversion = 100;
  } else if (final_conversion < 0 || !final_conversion) {
    final_conversion = 0;
  }

  let icons = {
    registration: tempregistration,
    clicks: tempclicks,
    comission: `${tempcomission.toFixed(2)}`,
    conversion: `${final_conversion.toFixed()}%`,
  };

  return {
    labels: labels,
    conversion: conversion,
    trafficlabels: trafficlabels,
    traffics: traffics,
    totalconversion: totalConversion,
    icons: icons,
  };
}
function timeconversiongraph(data) {
  let datelabels = [];
  let templabels = [];
  // let trafficdatelabels = [];
  let tempconversion = [];
  let temptraffic = [];
  let tempregistration = 0;
  let tempclicks = 0;
  let tempcomission = 0;
  console.log(">>>", data)
  data.forEach((resp) => {
    tempconversion.push(resp.conversion);
    temptraffic.push(1);
    tempregistration += resp.registration;
    tempclicks += resp.clicks;
    if (resp.conversion !== 0) {
      tempcomission += resp.comission;
    }
    let date = resp.created_at.split(" ");
    let dateflag = date[1] + " " + date[2];
    // datelabels.push(dateflag);
    let temptime = parseFloat(date[1].replace(/:/g, "."));

    templabels.push(temptime);
    datelabels.push(dateflag);
  });
  let dateobject = {};
  let trafficobject = {};
  // datelabels.forEach((dateflag, index) =>{
  //     dateobject[dateflag] = [];
  //     trafficobject[dateflag] = [];
  //     datelabels.filter((datelabel, index)=>{
  //       if(datelabel === dateflag){
  //         dateobject[dateflag].push(index);
  //         trafficobject[dateflag].push(index);
  //       }
  //     })
  // });

  templabels.forEach((temptime, index1) => {
    dateobject[datelabels[index1]] = [];
    trafficobject[datelabels[index1]] = [];
    templabels.filter((timelabel, index2) => {
      if (
        temptime === timelabel &&
        datelabels[index1].split(" ")[1] === datelabels[index2].split(" ")[1]
      ) {
        dateobject[datelabels[index1]].push(index2);
        trafficobject[datelabels[index1]].push(index2);
      }
    });
  });
  Object.keys(dateobject).forEach(function (key) {
    let sum = 0;
    let trafficsum = 0;
    dateobject[key].forEach((indices, ind) => {
      sum += tempconversion[indices];
      trafficsum += temptraffic[indices];
    });
    if (sum !== 0) {
      dateobject[key] = sum;
    } else {
      delete dateobject[key];
    }
    trafficobject[key] = trafficsum;
  });
  let conversion = Object.values(dateobject);
  let labels = Object.keys(dateobject);
  let trafficlabels = Object.keys(trafficobject);
  let traffics = Object.values(trafficobject);
  let totalConversion = conversion.reduce((a, b) => a + b, 0);
  let icons = {
    registration: tempregistration,
    clicks: tempclicks,
    // "comission":tempcomission,
    conversion: totalConversion,
  };

  //1) combine the arrays:
  var list = [];
  for (var j = 0; j < labels.length; j++) {
    list.push({ labels: labels[j], conversion: conversion[j] });
  }

  list.sort(function (a, b) {
    return a.labels < b.labels ? -1 : a.labels === b.labels ? 0 : 1;
  });

  //3) separate them back out:
  for (var k = 0; k < list.length; k++) {
    labels[k] = list[k].labels;
    conversion[k] = list[k].conversion;
  }
  // console.log(conversion);
  // console.log(labels);
  return {
    labels: labels,
    conversion: conversion,
    trafficlabels: trafficlabels,
    traffics: traffics,
    totalconversion: totalConversion,
    icons: icons,
  };
}
export { timegraph, daygraph, dayconversiongraph, timeconversiongraph };
