//This component can be used as number, text,testarea, email, password field
import React from "react";

function Selectbox(props) {
  const { ...inputfielddata } = props.inputfielddata;

  const handleChange = (event) => {
    const { value } = event.target;
    props.onChange(value);
  };
  return (
    <div className={inputfielddata["wrapcss"]}>
      {inputfielddata["label"] !== undefined ||
      inputfielddata["label"] !== "" ? (
        <span className={inputfielddata["labelcss"]}>
          {inputfielddata["label"]}
        </span>
      ) : (
        ""
      )}

      <select
        value={props.selectedOption}
        className={inputfielddata["inputfieldcss"]}
        onChange={handleChange}
        disabled={inputfielddata.disabled}
      >
        <option
          disabled={props.disable_placeholder}
          value={inputfielddata["placeholder"].value}
        >
          {inputfielddata["placeholder"].label}
        </option>
        {inputfielddata["data"].map((item, key) => (
          <option key={key} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
}
export default Selectbox;
