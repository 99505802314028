import React from 'react';

function Image(props) {
  const { src, alt, width, height, style } = props.imagedata;
  return (
    <img src={src} className={style} alt={alt} width={width} height={height} />
  );
}

export default Image;
