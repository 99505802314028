import React from "react";

const Error = ({ message }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 100 100"
        className="error-icon"
      >
        <circle
          cx="50"
          cy="50"
          r="40"
          stroke="red"
          strokeWidth="5"
          fill="white"
        />
        <line x1="30" y1="30" x2="70" y2="70" stroke="red" strokeWidth="5" />
        <line x1="30" y1="70" x2="70" y2="30" stroke="red" strokeWidth="5" />
      </svg>
      <p>{message ?? "Oops! Something went wrong."}</p>
    </div>
  );
};

export default Error;
