import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import IconTextBox from 'src/components/IconTextBox/IconTextBox';
import data from 'src/content/LoginPage';
import { useUserAuth } from "src/context/UserAuthContext";

type Errors = {
  email?: string
  password?: string
}
function ResetPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loginbutton, setLoginbutton] = useState("Submit");
  const navigate = useNavigate();
  const { spinner, user, resetPassword } = useUserAuth();
  const [errors, setErrors] = useState<Errors>({})

  useEffect(() => {
    if (user?.hasOwnProperty("uid")) {
      navigate("/home");
    }
  }, [user, navigate]);
  data.inputfields.submitbutton.label = loginbutton

  const validateForm = () => {
    let errs: any = {}
    setErrors(errs)
    if (email.length === 0) {
      errs.email = 'Email is required';
    }

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (email.length > 0 && !re.test(String(email).toLowerCase())) {
      errs.email = 'Enter a correct email';
    }
    setErrors(errs)
    return Object.keys(errs).length === 0
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('')
    if (!validateForm()) return;
    try {
      setLoginbutton("Resetting..");
      let { message, status } = await resetPassword(email)
      if (status !== 'success') {
        setErrors({ email: message });
      } else {
        setMessage(message)
      }
      setLoginbutton('Submit')
    } catch (err) {
      setLoginbutton('Submit')
      setErrors({ email: err.message });
      console.error(err)
    }
  };
  if (spinner) {
    return (
      <React.Fragment>
        <div className="flex flex-col justify-center items-center h-screen w-full bg-primary opacity-7">
          <span className="flex h-12 w-12">
            <span className="animate-ping absolute inline-flex h-12 w-12 rounded-full bg-sky-400 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-12 w-12 bg-sky-300"></span>
          </span>
          <h1 className="pt-8 animate-bounce text-4xl text-white">Loading....</h1>
        </div>
      </React.Fragment>
    );

  }
  return (
    <React.Fragment>
      <div className="flex flex-col-reverse md:flex-row">
        <div className="px-6 flex flex-col justify-center logincontentbackground md:min-h-screen md:max-w-sm lg:max-w-lg xl:max-w-xl py-20 ">
          <div className="relative -mt-10 text-white">
            <span className="text-2xl  lg:text-5xl font-semibold">Affiliate</span>
            <h1 className="text-5xl  lg:text-8xl font-bold logintextbackground">Sign in</h1>
          </div>
          <div className=" text-white pt-6 pb-8 pr-12 flex flex-wrap">
            {data.icontextbox.map((item, key) => (
              <IconTextBox key={key} icontextboxdata={item}></IconTextBox>
            ))}
          </div>
        </div>
        <div className="loginformbackground md:min-h-screen py-12 w-full flex items-center justify-center flex-col">

          <form onSubmit={handleSubmit} className="w-80 px-2 grid gap-3">
            <TextInput
              required
              name='email'
              type='email'
              label='Email'
              error={errors.email}
              placeholder='Enter your email'
              value={email}
              onChange={({ target }) => setEmail(target.value)} />
            <button disabled={loginbutton === 'Resetting..'} className='w-full text-center px-4 py-2 text-lg bg-primary text-white shadow rounded-lg'>
              {loginbutton}
            </button>
            {message && <p className='text-center text-green-600 font-medium text-lg'>{message}</p>}
            <a href='/' className=''>
              <button type='button' className='text-white underline underline-offset-[6px]'>Back to login</button>
            </a>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ResetPassword

const TextInput = ({ label, error, type, ...props }: {
  label?: string
  error?: string,
} & React.InputHTMLAttributes<HTMLInputElement>) => {

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const isPassword = type === 'password';

  const handlePasswordVisibility = () => setPasswordVisibility(!passwordVisibility);

  const passwordInputType = passwordVisibility ? 'text' : 'password';

  const VisibleIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 12 12"><path fill="currentColor" d="M1.974 6.659a.5.5 0 0 1-.948-.317c-.01.03 0-.001 0-.001a2 2 0 0 1 .062-.162c.04-.095.099-.226.18-.381c.165-.31.422-.723.801-1.136C2.834 3.827 4.087 3 6 3s3.166.827 3.931 1.662a5.5 5.5 0 0 1 .98 1.517l.046.113c.003.008.013.06.023.11L11 6.5s.084.333-.342.474a.5.5 0 0 1-.632-.314v-.003l-.006-.016l-.031-.078a4.5 4.5 0 0 0-.795-1.226C8.584 4.674 7.587 4 6 4s-2.584.673-3.194 1.338a4.5 4.5 0 0 0-.795 1.225l-.03.078zM4 7a2 2 0 1 1 4 0a2 2 0 0 1-4 0" /></svg>;
  const HideIcon = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16"><path fill="currentColor" d="m10.12 10.827l4.026 4.027a.5.5 0 0 0 .708-.708l-13-13a.5.5 0 1 0-.708.708l3.23 3.23A6 6 0 0 0 3.2 6.182a6.7 6.7 0 0 0-1.117 1.982c-.021.061-.047.145-.047.145l-.018.062s-.076.497.355.611a.5.5 0 0 0 .611-.355l.001-.003l.008-.025l.035-.109a5.7 5.7 0 0 1 .945-1.674a5 5 0 0 1 1.124-1.014L6.675 7.38a2.5 2.5 0 1 0 3.446 3.446m-3.8-6.628l.854.854Q7.564 5 8 5c2.044 0 3.286.912 4.028 1.817a5.7 5.7 0 0 1 .945 1.674q.025.073.035.109l.008.025v.003l.001.001a.5.5 0 0 0 .966-.257v-.003l-.001-.004l-.004-.013a2 2 0 0 0-.06-.187a6.7 6.7 0 0 0-1.117-1.982C11.905 5.089 10.396 4 8.002 4c-.618 0-1.177.072-1.681.199" /></svg>

  return (
    <div className="flex gap-1 flex-col">
      {label && <label className="text-sm text-white">
        {label}
        {props.required && <span className="text-red-400">*</span>}
      </label>}
      <div className='form-input flex group border-0 border-b-2 rounded border-gray-200 bg-white'>
        <input
          {...props}
          type={isPassword ? passwordInputType : type}
          className="w-full outline-none "
        />
        {isPassword && (
          <button className="cursor-pointer inline-block" onClick={handlePasswordVisibility}>
            {passwordVisibility ? HideIcon : VisibleIcon}
          </button>
        )}
      </div>
      <p role='alert' className='text-red-400 font-semibold'>{error}</p>
    </div>
  )
}