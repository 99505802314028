import React, { Suspense, lazy } from "react";
import "src/App.css";
import MainLayout from "src/Layout/MainLayout";
import Spinner from "src/components/Spinner/Spinner";

const ConversionGraph = lazy(() =>
  import("src/components/Graph/ConversionGraph")
);
const MapChart = lazy(() => import("src/components/MapChart/MapChart"));
const TimeLine = lazy(() => import("src/components/TimeLine/TimeLine.js"));
const DashboardCampaignTable = lazy(() =>
  import("./DashboardCampaignTable.js")
);
const DashboardEearnings = lazy(() => import("./DashboardEearnings.js"));

function Dashboard() {
  return (
    <MainLayout color="bg-gray-100">
      <div className="grid gap-2">
        <Suspense fallback={<Spinner />}>
          <DashboardEearnings />
        </Suspense>
        <div className="flex items-stretch flex-col  xl:flex-row gap-2  xl:gap-2 w-full">
          <div className="w-full xl:w-2/3">
            <Suspense fallback={<Spinner />}>
              <ConversionGraph />
            </Suspense>
          </div>
          <div className="w-full xl:w-1/3 shadow-lg bg-white p-2">
            <Suspense fallback={<Spinner />}>
              <MapChart />
            </Suspense>
          </div>
        </div>
        <div className="flex flex-wrap items-start xl:flex-nowrap gap-4 justify-between">
          <Suspense fallback={<Spinner />}>
            <DashboardCampaignTable />
          </Suspense>
          <Suspense fallback={<Spinner />}>
            <TimeLine />
          </Suspense>
        </div>
      </div>
    </MainLayout>
  );
}

export default Dashboard;
