import axios from "axios";
import { createContext, useContext, useState } from "react";


const ThemeContext = createContext(undefined);
export function ThemeContextProvider({ children }) {
  const [themes, setThemes] = useState({ status: "init", data: [] });

  async function themeDetails(params) {
    setThemes({ status: "init", data: [] });

    setThemes(await fetchTheme(params));
  }

  const fetchTheme = async (params) => {
    try {
      let { data: response } = await axios.get("/api/affliate/theme", {
        params: {
          ...params,
          requestName: "GET THEMES"
        }
      })
      const { data } = response;
      return { status: "success", data }
    } catch (error) {
      return { status: 'error', data: [] }
    }
  }

  return (
    <ThemeContext.Provider
      value={{
        themes,
        themeDetails,
        fetchTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export function useThemeContext() {
  return useContext(ThemeContext);
}
