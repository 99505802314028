import { MantineProvider } from "@mantine/core";
import axios from "axios";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import LoadingPage from "src/Pages/LoadingPage";

const SiteDetailsContext = createContext(undefined);
export function SiteDetailsContextProvider({ children }) {
  const [site, setSite] = useState(null);
  let callRef = useRef(null);


  useEffect(() => {
    let ignore = false;
    const { host } = window.location;
    const isDEV = host.includes("localhost") || host.includes("127.0.0.1")
    if (!ignore && !callRef.current) {
      callRef.current = true;
      axios.get("/api/affliate/sites/" + (isDEV ? 'affliate.test.com' : host), {
        params: { requestName: "GET SITE DETAILS" }
      }).then((response) => {
        const { data } = response.data;
        setSite(data);
        localStorage.setItem("site", JSON.stringify(data));
      }).catch(err => {
        console.error(err);
      })
    }
    return () => {
      ignore = true;
    }
  }, []);

  useEffect(() => {
    var cacheSite = localStorage.getItem('site')
    try {
      if (cacheSite) {
        const cache = JSON.parse(cacheSite);
        setSite(cache)
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    const updateThemeColors = async (theme) => {
      if (!theme) {
        theme = {
          primary: "#0070f3",
          secondary: "#f9f9f9"
        }
      }

      Object.entries(theme).forEach(([name, value]) => {
        document.documentElement.style.setProperty(`--color-${name}`, value as any);
      });
    };
    if (site)
      updateThemeColors(site.theme);
  }, [site]);

  if (!site) return <LoadingPage />;


  return (
    <MantineProvider>
      <SiteDetailsContext.Provider value={{ site }}>
        {children}
      </SiteDetailsContext.Provider>
    </MantineProvider>
  );
}

export function useSiteContext() {
  return useContext(SiteDetailsContext);
}