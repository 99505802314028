const firebaseConfig = {
    "VHR": {
        apiKey: "AIzaSyBY3AhfMSg0kg5qXoZ4pNuN-q6xq0Scokg",
        authDomain: "vhr-classic.firebaseapp.com",
        projectId: "vhr-classic",
        storageBucket: "vhr-classic.appspot.com",
        messagingSenderId: "92679960742",
        appId: "1:92679960742:web:5bbcee20ebf2aeb6b6dc67",
        measurementId: "G-WFLTYVKSHR"
    },
    "AFT": {
        apiKey: "AIzaSyB4nAIRH1yvB86FpHBjc3cS0zshS7S7WbE",
        authDomain: "affiliates-admin-21289.firebaseapp.com",
        projectId: "affiliates-admin-21289",
        storageBucket: "affiliates-admin-21289.appspot.com",
        messagingSenderId: "295100439578",
        appId: "1:295100439578:web:194b7e4251d10239b305a3",
        measurementId: "G-2RCN7NJ9G4"
    },
    'AFT2': {
        apiKey: "AIzaSyB4nAIRH1yvB86FpHBjc3cS0zshS7S7WbE",
        authDomain: "affiliates-admin-21289.firebaseapp.com",
        projectId: "affiliates-admin-21289",
        storageBucket: "affiliates-admin-21289.appspot.com",
        messagingSenderId: "295100439578",
        appId: "1:295100439578:web:7f52d1503098336eb305a3",
        measurementId: "G-K7GQNEJPC0"
    }
};
export default firebaseConfig

