import React from 'react'

function LoadingPage() {
  return (
    <React.Fragment>
      <div className="flex flex-col justify-center items-center h-screen w-full bg-primary opacity-7">
        <span className="flex h-12 w-12">
          <span className="animate-ping absolute inline-flex h-12 w-12 rounded-full bg-sky-400 opacity-75"></span>
          <span className="relative inline-flex rounded-full h-12 w-12 bg-sky-300"></span>
        </span>
        <h1 className="pt-8 animate-bounce text-4xl text-white">Loading....</h1>
      </div>
    </React.Fragment>
  );
}

export default LoadingPage