import axios from "axios";
import { createContext, useContext, useState } from "react";


const MarketingToolsContext = createContext(undefined);
export function MarketingToolsContextProvider({ children }) {
  const [marketingTools, setMarketingTools] = useState({
    status: "init",
    data: [],
  });

  async function marketingToolsDetails(params) {
    setMarketingTools({ status: "init", data: [] });

    setMarketingTools(await fetchMarketingTools(params));
  }

  const fetchMarketingTools = async (params) =>
    axios
      .get("/api/affliate/marketing_assets", {
        params: { ...params, status: 1, requestName: "GET MARKETING TOOLS" },
      })
      .then((res) => ({ status: "success", data: res.data.data }))
      .catch((err) => ({ status: "error", data: [] }));

  return (
    <MarketingToolsContext.Provider
      value={{
        marketingTools,
        marketingToolsDetails,
      }}
    >
      {children}
    </MarketingToolsContext.Provider>
  );
}

export function useMarketingToolsContextContext() {
  return useContext(MarketingToolsContext);
}
