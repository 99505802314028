import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./config/protectedRoute";
import { SalesContextProvider } from "./context/SalesContext";

import Campaign from "./Pages/Campaign/Campaigns";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Auth/Login";
import { MarketingToolsContextProvider } from "./context/MarketingToolsContext";
import SuspenseWrapper from "./components/SuspenseWrapper";
import { useUserAuth } from "./context/UserAuthContext";
import ResetPassword from "./Pages/Auth/ResetPassword";

const Signup = lazy(() => import("./Pages/Auth/Signup"));
const MarketingTools = lazy(() => import("./Pages/MarketingTools"));
const Chargebacks = lazy(() => import("./Pages/Chargebacks"));
const Earnings = lazy(() => import("./Pages/Earnings"));
const Profile = lazy(() => import("./Pages/Profile"));
const TermsOfService = lazy(() => import("./Pages/TermsOfService"));
const SupportPage = lazy(() => import("./Pages/Support"));
const TermsOfServicePage = lazy(() => import("./Pages/TermsOfService"));
const AdminLogin = lazy(() => import("./Pages/AdminLogin"));
const CreateCampaign = lazy(() => import("./Pages/Campaign/CreateCampaign"));
const EditCampaign = lazy(() => import("./Pages/Campaign/EditCampaign"));

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function App() {
  const { userProfile, isLoading, isAdmin } = useUserAuth();
  const isEnabled = !userProfile || Number(userProfile?.status) === 1;
  const buildDisabledOverlay = () => {
    if (isLoading) return null;

    if (!isEnabled && !isAdmin)
      return (
        <div className="fixed z-10 bg-black/10 w-screen h-screen flex justify-center">
          <div className="h-24 text-xs sm:text-base bg-white w-full top-20 flex items-center text-red-600 justify-center shadow  flex-col">
            <h1 className="text-lg font-bold">CAUTION!</h1>
            <span>Your account is disabled.</span>
            <span>Please contact our support team to enable your account.</span>
          </div>
        </div>
      );
    return null;
  };

  return (
    <>
      {buildDisabledOverlay()}
      <Routes>
        <Route path="/admin-login" Component={AdminLogin} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <SuspenseWrapper>
                <Profile />
              </SuspenseWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/support"
          element={
            <ProtectedRoute>
              <SuspenseWrapper>
                <SupportPage />
              </SuspenseWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/terms-of-service"
          element={
            <ProtectedRoute>
              <SuspenseWrapper>
                <TermsOfServicePage />
              </SuspenseWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/chargebacks"
          element={
            <ProtectedRoute>
              <SalesContextProvider>
                <SuspenseWrapper>
                  <Chargebacks />
                </SuspenseWrapper>
              </SalesContextProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/earnings"
          element={
            <ProtectedRoute>
              <SuspenseWrapper>
                <Earnings />
              </SuspenseWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/marketing-tools"
          element={
            <ProtectedRoute>
              <MarketingToolsContextProvider>
                <SuspenseWrapper>
                  <MarketingTools />
                </SuspenseWrapper>
              </MarketingToolsContextProvider>
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/signup"
          element={
            <Suspense
              fallback={
                <div className="h-4 w-4 rounded-full border-b border-l animate-spin border-primary"></div>
              }
            >
              <Signup />
            </Suspense>
          }
        />
        <Route
          path="/campaigns"
          element={
            <ProtectedRoute>
              <SuspenseWrapper>
                <Campaign />
              </SuspenseWrapper>
            </ProtectedRoute>
          }
        />
        <Route
          path="/campaign/create"
          element={
            <ProtectedRoute>
              <MarketingToolsContextProvider>
                <SuspenseWrapper>
                  <CreateCampaign />
                </SuspenseWrapper>
              </MarketingToolsContextProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/campaign/edit/:id"
          element={
            <ProtectedRoute>
              <MarketingToolsContextProvider>
                <SuspenseWrapper>
                  <EditCampaign />
                </SuspenseWrapper>
              </MarketingToolsContextProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/terms"
          element={
            <SuspenseWrapper>
              <TermsOfService />
            </SuspenseWrapper>
          }
        />
      </Routes>
    </>
  );
}

export default App;
