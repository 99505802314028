import axios from "axios";
import { createContext, useContext, useState } from "react";
import { CampaignAnalytic, TimelineAnalytic, EarningStatisticAnalytic, SaleStatusStatisticAnalytic, TopCampaignAnalytic } from "../@types/analytics";

type AnalyticsContextProps = {
  campaignAnalytics: Analytics<CampaignAnalytic>
  loadCampaignAnalytics: (params) => void
  earningStatistics: Analytics<EarningStatisticAnalytic>
  loadEarningStatistics: (params) => void
  saleStatusStatistics: Analytics<SaleStatusStatisticAnalytic[]>
  loadSaleStatusStatistics: (params) => void
  topCampaignStatistics: Analytics<TopCampaignAnalytic[]>
  loadTopCampaignAnalytics: (params) => void
  saleStatistics: Analytics<any>
  loadSaleStatistics: (params) => void
  timelineAnalytics: Analytics<TimelineAnalytic[]>
  loadTimelineAnalytics: (params) => void
}

const analyticsContext = createContext<AnalyticsContextProps | undefined>(undefined);

interface Analytics<T> {
  data?: T
  status: 'init' | 'success' | 'error'
}

export function AnalyticsContextProvider({ children }) {
  const [timelineAnalytics, setTimelineAnalytics] = useState<Analytics<TimelineAnalytic[]>>({
    status: "init",
  });
  const [campaignAnalytics, setCampaignAnalytics] = useState<Analytics<CampaignAnalytic>>({
    status: "init",
  });
  const [earningStatistics, setEarningStatistics] = useState<Analytics<EarningStatisticAnalytic>>({
    status: "init",
  });
  const [saleStatusStatistics, setSaleStatusStatistics] = useState<Analytics<SaleStatusStatisticAnalytic[]>>({
    status: "init",
  });
  const [saleStatistics, setSaleStatistics] = useState<Analytics<any>>({
    status: "init",
  });
  const [topCampaignStatistics, setTopCampaignStatistics] = useState<Analytics<TopCampaignAnalytic[]>>({
    status: "init",
  });

  const loadTopCampaignAnalytics = async (params) => {
    setTopCampaignStatistics({
      status: "init",
    });

    setTopCampaignStatistics(await fetchTopCampaignAnalytics(params) as any);
  };

  const fetchTopCampaignAnalytics = async (params) =>
    axios
      .get("/api/affliate/analytics", {
        params: {
          ...params,
          type: "top_campaigns",
          requestName: "GET TOP CAMPAIGNS",
        },
      })
      .then((res) => ({
        status: "success",
        data: res.data.data,
      }))
      .catch((err) => ({ status: "error", data: [] }));

  const loadTimelineAnalytics = async (params) => {
    setTimelineAnalytics(await fetchTimelineAnalytics(params) as any);
  };

  const fetchTimelineAnalytics = async (params) =>
    axios
      .get("/api/affliate/analytics", {
        params: { ...params, type: "timeline", requestName: "GET TIMELINE ANALYTICS" },
      })
      .then((res) => ({
        status: "success",
        data: res.data.data,
      }))
      .catch((err) => ({ status: "error", data: [] }));

  const loadCampaignAnalytics = async (params) => {
    setCampaignAnalytics({
      status: "init",
    });

    setCampaignAnalytics(await fetchCampaignAnalytics(params) as any);
  };

  const fetchCampaignAnalytics = async (params) =>
    axios
      .get("/api/affliate/analytics", {
        params: { ...params, type: "campaign", requestName: "GET CAMPAIGN ANALYTICS" },
      })
      .then((res) => ({
        status: "success",
        data: res.data.data,
      }))
      .catch((err) => ({ status: "error", data: [] }));

  const loadEarningStatistics = async (params) => {
    setEarningStatistics({
      status: "init",
    });
    setEarningStatistics(await fetchEarningStatistics(params) as any);
  };

  const fetchEarningStatistics = async (params) =>
    axios
      .get("/api/affliate/analytics", {
        params: {
          ...params,
          type: "statistics",
          group_by: "week_day",
          requestName: "GET EARNING STATISTICS",
        },
      })
      .then((res) => ({
        status: "success",
        data: res.data.data,
      }))
      .catch((err) => ({ status: "error", data: [] }));

  const loadSaleStatusStatistics = async (params) => {
    setSaleStatusStatistics({
      status: "init",
    });
    setSaleStatusStatistics(await fetchSaleStatusStatistics(params) as any);
  };

  const fetchSaleStatusStatistics = async (params) =>
    axios
      .get("/api/affliate/analytics", {
        params: {
          ...params,
          type: "sale_status",
          requestName: "GET SALE STATUS STATISTICS: " + JSON.stringify(params),
        },
      })
      .then((res) => ({
        status: "success",
        data: res.data.data,
      }))
      .catch((err) => ({ status: "error", data: [] }));

  const loadSaleStatistics = async (params) => {
    setSaleStatistics({
      status: "init",
    });
    setSaleStatistics(await fetchSaleStatistics(params) as any);
  };

  const fetchSaleStatistics = async (params) =>
    axios
      .get("/api/affliate/analytics", {
        params: {
          ...params,
          type: "sales",
          requestName: "GET SALE STATISTICS: " + JSON.stringify(params),
        },
      })
      .then((res) => ({
        status: "success",
        data: res.data.data,
      }))
      .catch((err) => ({ status: "error", data: [] }));

  return (
    <analyticsContext.Provider
      value={{
        campaignAnalytics,
        loadCampaignAnalytics,
        earningStatistics,
        loadEarningStatistics,
        saleStatusStatistics,
        loadSaleStatusStatistics,
        topCampaignStatistics,
        loadTopCampaignAnalytics,
        saleStatistics,
        loadSaleStatistics,
        timelineAnalytics,
        loadTimelineAnalytics,
      }}
    >
      {children}
    </analyticsContext.Provider>
  );
}

export function useAnalyticsContext() {
  return useContext(analyticsContext);
}
