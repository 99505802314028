import axios from "axios";
import { initializeApp } from "firebase/app";

import {
  Auth,
  User,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  signInWithCustomToken,
  sendPasswordResetEmail,
} from "firebase/auth";

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import firebaseConfig from "../config/firebase";
import { useSiteContext } from "./SiteContext";
import { toast } from "react-toastify";
import usePayout from "../hooks/use-payout-provider";
import {
  LoginResponse,
  SignupUserForm,
  UserAuthContextType,
  UserProfile,
  Response as Resp,
} from "src/@types/auth";

const UserAuthContext = createContext<UserAuthContextType | undefined>(
  undefined
);

export function UserAuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [user, setUser] = useState<User | {}>({});
  const [spinner, setSpinner] = useState(true);
  const [isAdmin, setIsAdmin] = useState(
    localStorage.getItem("isAdmin") == "true" ? true : false
  );
  const { site } = useSiteContext();
  const [auth, setAuth] = useState<Auth | undefined>(undefined);
  const val = JSON.parse(localStorage.getItem("userdetails"));
  const [userProfile, setUserProfile] = useState<UserProfile>(val);

  const { addPayoutProvider, updatePayoutProvider, deletePayoutProvider } =
    usePayout(userProfile, setUserProfile);

  const signInWithToken = useCallback(
    async (token: string, id: string): Promise<User> => {
      if (!auth) return;

      setIsAdmin(true);

      await logOuttoken();
      let user;

      try {
        user = await signInWithCustomToken(auth, token);
      } catch (error) {
        console.log(error.code);

        if ((error.code = "auth/user-disabled")) {
          console.log("allowing...");

          user = {
            user: { uid: id },
          };
        } else {
          throw new Error("Unable to access Account");
        }
      }

      let resp = await axios.get("/api/affliate/users/" + user.user.uid, {
        params: {
          requestName: "GET USER DETAILS",
        },
      });

      let userDetails = resp.data;

      if (!user?.user?.email) {
        user.user.email = userDetails.email;
      }

      updateUser(user.user);
      setUserProfile(userDetails);

      localStorage.setItem("isAdmin", "true");
      localStorage.setItem("user", JSON.stringify(user.user));
      localStorage.setItem("userdetails", JSON.stringify(userDetails));

      return user.user;
    },
    [auth]
  );

  async function logIn(email, password): Promise<LoginResponse> {
    try {
      if (!auth) return;
      let userdata = await signInWithEmailAndPassword(auth, email, password);
      let resp = await axios.get("/api/affliate/users/" + userdata.user.uid, {
        params: { requestName: "GET USER DETAILS" },
      });
      let userDetails = resp.data;
      updateUser(userdata.user);
      setUserProfile(userDetails);
      localStorage.setItem("user", JSON.stringify(userdata.user));
      localStorage.setItem("userdetails", JSON.stringify(userDetails));
      return {
        user: userdata.user,
      };
    } catch (error) {
      return {
        error: {
          error: error.message,
          message: "Invalid Email or Password...",
        },
      };
    }
  }

  const signup = async (form: SignupUserForm): Promise<User | undefined> => {
    try {
      if (!auth) return;
      let requiredKeys = [
        "id",
        "site_id",
        "password",
        "user_unique_id",
        "email",
        "mobile",
        "first_name",
        "last_name",
        "city",
        "state",
        "country",
        "zipcode",
        "address",
        "status",
        "skype",
        "partner_type",
        "social",
        "timezone",
        "reach_time",
        "website",
        "currency",
        "company_name",
      ];
      form.site_id = site?._id;

      let extraKeys = Object.keys(form).filter(
        (key) => !requiredKeys.includes(key)
      );
      for (let key of extraKeys) {
        delete form[key];
      }

      let { data: response } = await axios.post("/api/affliate/users/", form, {
        params: { requestName: "SIGNING UP USER" },
      });
      const { data } = response;
      if (data._id) {
        const { user } = await logIn(form.email, form.password);
        return user;
      }
    } catch (error) {
      const { data } = error?.response;
      toast.error(data?.message || error.message, {
        autoClose: 3000,
      });
    }
  };

  const updateUser = (user: User) => {
    setCurrentUser(user);
    setUser(user);
  };

  async function changePassword(
    email: string,
    currentPassword: string,
    password: string
  ) {
    if (!currentUser) return;
    const app = initializeApp(firebaseConfig[site?.code], site.code);
    var auth = getAuth(app);
    try {
      const { user } = await signInWithEmailAndPassword(
        auth,
        email,
        currentPassword
      );
      setCurrentUser(user);
      setUser(user);
      await updatePassword(user, password);
      toast.success("Password changed successfully");
    } catch (error) {
      console.error(error.message);
      toast.error(error.message);
    }
  }

  async function logOuttoken() {
    localStorage.removeItem("user");
    localStorage.removeItem("userdetails");
    localStorage.removeItem("isAdmin");
    await signOut(auth as any);
    setUser({});
    setCurrentUser(null);
  }

  async function logOut() {
    localStorage.removeItem("user");
    localStorage.removeItem("userdetails");
    localStorage.removeItem("isAdmin");
    await signOut(auth as any);
    setUser({});
    setCurrentUser(null);
    setAuth(null);
    return window.location.replace("/");
  }

  const updateProfile = async (data) => {
    data.id = userProfile?._id;
    let entries = Object.entries(data);
    data = Object.fromEntries(
      entries.map(([key, value]) => {
        return [key.toLowerCase().replace(" ", "_"), value];
      })
    );
    if ("address" in data) {
      data.address = { address_line_1: data.address };
    }
    let resp = await axios
      .put("/api/affliate/users/", data, {
        params: { requestName: "UPDATE USER DETAILS (PROFILE)" },
      })
      .then((res) => {
        localStorage.setItem("userdetails", JSON.stringify(res.data.data));
        setUserProfile(res.data.data);
        toast.success("Changes applied successfully");
        return res.data.data;
      })
      .catch((err) => {
        console.error(err);
      });
    return resp;
  };

  const resetPassword = async (email: string): Promise<Resp> => {
    try {
      let { data } = await axios.get("/api/affliate/users/", {
        params: { requestName: "Resetting email", email: email },
      });

      if (data.message) {
        return {
          message: "No user with this email",
          status: "error",
        };
      }
    } catch (error) {
      return {
        message: "No user with this email",
        status: "error",
      };
    }
    await sendPasswordResetEmail(auth, email);
    return {
      message: "Password reset link sent to your email",
      status: "success",
    };
  };

  useEffect(() => {
    let ignore = false;

    if (site) {
      const app = initializeApp(firebaseConfig[site?.code]);
      const authinit = getAuth(app);

      if (!ignore && !auth) {
        setAuth(authinit);
        return;
      }

      const unsubscribe = onAuthStateChanged(authinit, (currentUser) => {
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
          let foundUser = JSON.parse(loggedInUser);
          setUser(foundUser);
          axios
            .get("/api/affliate/users/" + foundUser.uid, {
              params: { requestName: "GET USER DETAILS" },
            })
            .then((res) => {
              localStorage.setItem("userdetails", JSON.stringify(res.data));
              setUserProfile(res.data);
              updateUser(foundUser);
              setSpinner(false);
            })
            .catch((err) => {
              console.error(err);
              setSpinner(false);
            });
        } else {
          if ((currentUser as any)?.user) {
            updateUser((currentUser as any).user);
          }
          setSpinner(false);
        }
      });
      return () => {
        unsubscribe();
        ignore = true;
      };
    }
  }, [site, auth]);

  return (
    <UserAuthContext.Provider
      value={{
        signup,
        user,
        logIn,
        spinner,
        updateProfile,
        logOut,
        addPayoutProvider,
        updatePayoutProvider,
        deletePayoutProvider,
        userProfile,
        changePassword,
        auth,
        updateUser,
        isLoading: spinner,
        signInWithToken,
        resetPassword,
        isAdmin,
      }}
    >
      {children}
    </UserAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(UserAuthContext);
}
