import axios from "axios";
import { createContext, useContext, useState } from "react";

const productContext = createContext(undefined);
export function ProductContextProvider({ children }) {
  const [products, setProducts] = useState({ status: "init", data: [] });

  async function productDetails(params) {
    setProducts({ status: "init", data: [] });

    setProducts(await fetchProduct({ ...params, public: 1 }));
  }

  const fetchProduct = async (params) =>
    axios
      .get("/api/affliate/products", {
        params: { ...params, status: 1, requestName: "GET PRODUCTS" },
      })
      .then((res) => ({ status: "success", data: res.data.data }))
      .catch((err) => ({ status: "error", data: [] }));

  return (
    <productContext.Provider
      value={{
        products,
        productDetails,
        fetchProduct,
      }}
    >
      {children}
    </productContext.Provider>
  );
}

export function useProductContext() {
  return useContext(productContext);
}
