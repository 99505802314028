import axios from "axios";
import { createContext, useContext, useState } from "react";
import { daygraph, timegraph } from "../helper/helper";
import { dataExist } from "../helper/utils";

type CampaignContextProps = {
  labels: any;
  sales: any;
  totalsale: { status: string; data: string };
  campaigns: { status: string; data: any };
  fetchCampaign: any;
  createCampaign: any;
  updateCampaign: any;
  deleteCampaign: any;
  salehistory: any;
  loading: boolean;
};

const CampaignContext = createContext(undefined);
export function CampaignContextProvider({ children }) {
  const [labels, setLabels] = useState([]);
  const [sales, setSales] = useState([]);
  const [totalsale, setTotalSales] = useState({
    status: "init",
    data: "",
  });
  const [campaigns, setCampaigns] = useState({ status: "init", data: [] });
  const [loading, setLoading] = useState(false);

  function salehistory(params, graphtype = "date") {
    setLabels([]);
    setSales([]);
    setTotalSales({
      status: "init",
      data: "",
    });

    if (!params?.user_id) return;

    axios
      .get("/api/affliate/sales", {
        params: { ...params, requestName: "GET SALES HISTORY" },
      })
      .then((res) => {
        if (graphtype === "time") {
          let timegraphdata = timegraph(res.data.data);
          setLabels(timegraphdata.labels);
          setSales(timegraphdata.sales);
          setTotalSales({
            status: "success",
            data: "$" + timegraphdata.totalsales.toFixed(2),
          });
        } else {
          let daygraphdata = daygraph(res.data.data);
          setLabels(daygraphdata.labels);
          setSales(daygraphdata.sales);
          setTotalSales({
            status: "success",
            data: "$" + daygraphdata.totalsales.toFixed(2),
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  async function campaignDetails(params) {
    setCampaigns({ status: "init", data: [] });
    setLoading(true);

    setCampaigns(await fetchCampaign(params));
    setLoading(false);
  }

  const fetchCampaign = async (params) => {
    if (!params?.user_id) return { status: "error", data: [] };
    setLoading(true);
    return axios
      .get("/api/affliate/campaign", {
        params: { ...params, requestName: "GET CAMPAIGN" },
      })
      .then((res) => {
        setLoading(false);
        return { status: "success", data: res.data.data };
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        return { status: "error", data: [] };
      });
  };

  const createCampaign = async (data) =>
    axios
      .post(
        "/api/affliate/campaign",
        {
          ...(data.id && { id: data.id }),
          ...(data.user_id && { user_id: data.user_id }),
          ...(data.product_id && { product_id: data.product_id }),
          ...(data.theme_id && { theme_id: data.theme_id }),
          // ...(data.asset_id && { asset_id: data.asset_id }),
          ...(data.site_id && { site_id: data.site_id }),
          ...(data.name && { name: data.name }),
          ...(data.promotion && { promotion: data.promotion }),
          ...(dataExist(data.status) && { status: data.status }),
        },
        {
          params: {
            requestName: "CAMPAIGN CREATED: " + JSON.stringify(data),
          },
        }
      )
      .then((res) => ({ status: "success", data: res.data.data }))
      .catch((err) => ({
        status: "error",
        data: [],
        error: err.response?.data?.error_fields?.[0] ?? {},
      }));

  const updateCampaign = async (data) =>
    axios
      .put(
        "/api/affliate/campaign",
        {
          ...(data.id && { id: data.id }),
          ...(data.user_id && { user_id: data.user_id }),
          ...(data.product_id && { product_id: data.product_id }),
          ...(data.theme_id && { theme_id: data.theme_id }),
          // ...(data.asset_id && { asset_id: data.asset_id }),
          ...(data.site_id && { site_id: data.site_id }),
          ...(data.name && { name: data.name }),
          ...(dataExist(data.status) && { status: data.status }),
        },
        {
          params: {
            requestName: "UPDATE CAMPAIGN",
          },
        }
      )
      .then((res) => ({ status: "success", data: res.data.data }))
      .catch((err) => ({
        status: "error",
        data: [],
        error: err.response?.data?.error_fields?.[0] ?? {},
      }));

  const deleteCampaign = async (params) =>
    axios
      .delete(
        `/api/affliate/campaign?${new URLSearchParams({
          id: params.id,
        }).toString()}`,
        {
          params: {
            requestName: "DELETE CAMPAIGN",
          },
        }
      )
      .then((res) => {
        setCampaigns({
          ...campaigns,
          data: campaigns.data.filter((item) => item._id !== params.id),
        });

        return { status: "success", data: res.data.data };
      })
      .catch((err) => ({ status: "error", data: [] }));
  return (
    <CampaignContext.Provider
      value={{
        labels,
        sales,
        totalsale,
        loading,
        salehistory,
        campaigns,
        campaignDetails,
        fetchCampaign,
        createCampaign,
        updateCampaign,
        deleteCampaign,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
}

export function useCampaignContext() {
  return useContext(CampaignContext) as CampaignContextProps;
}
