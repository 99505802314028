import React from "react";
import { Navigate } from "react-router-dom";
// import { useUserAuth } from "../context/UserAuthContext";
const ProtectedRoute = ({ children }) => {
// const { user } = useUserAuth();
const loggedInUser = localStorage.getItem("user");
  if (loggedInUser) {
  // console.log("Check user in Private: ", user);
  // if (user.hasOwnProperty("uid")) {
    return children;
  }else{
    return <Navigate to="/" />;
  }
};

export default ProtectedRoute;