import * as OutlineHIcons from "@heroicons/react/outline";
import * as SolidHIcons from "@heroicons/react/solid";
import React from "react";

function Button(props) {
  const { css, label, icon, iconcss, icontype, buttontype, disabled } =
    props.buttondata;

  const icons =
    icontype === "solid" ? { ...SolidHIcons } : { ...OutlineHIcons };

  const Icon = icons[icon];

  return (
    <button
      className={css}
      type={buttontype}
      onClick={props.onPress}
      disabled={props.disabled}
    >
      {icon && <Icon className={iconcss} />}
      {label}
    </button>
  );
}

export default Button;
