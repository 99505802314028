import axios from "axios";
import moment from 'moment';
import { createContext, useContext, useState } from "react";
// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
// axios.defaults.headers.common["x-authkey"] = process.env.APP_AUTH_KEY;

const SalesContext = createContext(undefined);
export function SalesContextProvider({ children }) {
    const [salesList, setSalesList] = useState([])
    const [graphsalesList, setgraphSalesList] = useState([])

    function salesDetails(params) {
        axios.get("/api/affliate/sales", {
            params: {
                ...params,
                requestName: 'GET SALES DETAILS',
            },
        }).then((res) => {
            let resp = changesaleListStructure(res.data.data);
            setSalesList(resp);
            return resp;
        }).catch(err => {
            console.error(err);
        })
    }
    function salesgraphDetails(params) {
        axios.get("/api/affliate/sales", {
            params: {
                ...params,
                requestName: 'GET SALES GRAPH DETAILS',
            }
        }).then((res) => {
            let resp = changeGraphStructure(res.data.data, params.start_date, params.end_date);
            console.log(">>>", resp)
            setgraphSalesList(resp as any);
            return resp;
        }).catch(err => {
            console.error(err);
        })
    }
    function changeGraphStructure(data, startDate, endDate) {
        startDate = moment(startDate);
        endDate = moment(endDate);
        const totalsale = data.length;
        const totalDays = moment.duration(endDate.diff(startDate)).asDays() + 1;

        var successfullsale = data.filter((d) => {
            if (d.status === 1) {
                return d;
            }
        });
        var chargeback = data.filter((d) => {
            if (d.status === 2) {
                return d;
            }
        });
        console.log("chargeback",chargeback)
        console.log("successfullsale",successfullsale)
        const saledata = totalpercentage(totalDays, startDate, successfullsale, totalsale);
        const chargebackdata = totalpercentage(totalDays, startDate, chargeback, totalsale);
        console.log("chargebackdata",chargebackdata);
        
        let successfullsalepercentage = ((successfullsale.length / totalsale) * 100) | 0;
        let chargebackpercentage = ((chargeback.length / totalsale) * 100) | 0;
        console.log("chargebackpercentage",chargebackpercentage)
        return { 'saleGraphData': saledata, 'chargebackGraphData': chargebackdata, 'totalsales': successfullsalepercentage, 'totalchargeback': chargebackpercentage }
    }
    function totalpercentage(totalDays, startDate, data, totalsale) {
        let addDataByDays = {
            data: [],
            labels: []
        };
    
        let new_date = startDate.clone(); // Use clone to avoid mutating the original startDate
    
        for (let i = 0; i < totalDays; i++) {
            if (i > 0) {
                new_date = startDate.clone().add(i, "days");
            }
    
            let totalperc = 0;
            for (let j = 0; j < data.length; j++) {
                // Correctly parse the date string and format it consistently
                let sale_date = moment(data[j].created_at, "YYYY/MM/DD HH:mm A");
    
                if (sale_date.isSame(new_date, 'day')) {
                    totalperc++;
                }
            }
    
            addDataByDays.labels.push(new_date.format('YYYY-MM-DD'));
            addDataByDays.data.push((((totalperc / totalsale) * 100) | 0));
        }
    
        return addDataByDays;
    }
    
    function changesaleListStructure(data) {
        let tabledata = [];
        data.forEach((sale) => {
            tabledata.push({ 'orderNo': sale.id, 'requestId': sale.user_id, 'price': sale.price, 'refundType': sale.sale_note, 'status': 'Approved' })
        });
        return tabledata;
    }
    return (
        <SalesContext.Provider
            value={{ salesList, salesDetails, graphsalesList, salesgraphDetails }}
        >
            {children}
        </SalesContext.Provider>
    );
}

export function useSalesContext() {
    return useContext(SalesContext);
}