const data = {
    "icontextbox": [
        {
            "wrapcss": "flex w-1/2 md:w-full xl:w-1/2 gap-2",
            "contentwrapcss": "grid",
            "icon": "",
            "iconcss": "",
            "icontype": "",
            "label": "40% commission per sale-highest in the industry",
            "text": "",
            "textcss": "text-sm text-slate-300",
            "labelcss": "text-sm lg:text-lg p-2 text-left",
            "imagedata": {
                "src": process.env.PUBLIC_URL + "/icons/icon-money.svg",
                "alt": "Icon",
                "width": "80px",
                "height": "80px",
                "style": "w-8 lg:w-12"
            }
        },
        {
            "wrapcss": "flex w-1/2 md:w-full xl:w-1/2 gap-1  lg:gap-2",
            "contentwrapcss": "flex flex-col",
            "icon": "",
            "iconcss": "",
            "icontype": "",
            "label": "Unlimited referrals and no commission caps",
            "text": "",
            "textcss": "text-sm text-slate-300",
            "labelcss": "text-sm lg:text-lg p-2 text-left",
            "imagedata": {
                "src": process.env.PUBLIC_URL + "/icons/icon-unlimited-ref.svg",
                "alt": "Icon",
                "width": "80px",
                "height": "80px",
                "style": "w-8  lg:w-12"
            }
        },
        {
            "wrapcss": "flex w-1/2 md:w-full xl:w-1/2 gap-1  lg:gap-2",
            "contentwrapcss": "flex flex-col",
            "icon": "",
            "iconcss": "",
            "icontype": "",
            "label": "Product pricing control and unique coupon codes",
            "text": "",
            "textcss": "text-sm text-slate-300",
            "labelcss": "text-sm lg:text-lg p-2 text-left",
            "imagedata": {
                "src": process.env.PUBLIC_URL + "/icons/icon-product-pricing.svg",
                "alt": "Icon",
                "width": "80px",
                "height": "80px",
                "style": "w-8  lg:w-12"

            }
        },
        {
            "wrapcss": "flex w-1/2 md:w-full xl:w-1/2 gap-1  lg:gap-2",
            "contentwrapcss": "flex flex-col",
            "icon": "",
            "iconcss": "",
            "icontype": "",
            "label": "Dedicated success account manager for help",
            "text": "",
            "textcss": "text-sm text-slate-300",
            "labelcss": "text-sm lg:text-lg p-2 text-left",
            "imagedata": {
                "src": process.env.PUBLIC_URL + "/icons/icon-account-manager.svg",
                "alt": "Icon",
                "width": "80px",
                "height": "80px",
                "style": "w-8  lg:w-12"
            }
        },
        {
            "wrapcss": "flex w-1/2 md:w-full xl:w-1/2 gap-1  lg:gap-2",
            "contentwrapcss": "flex flex-col",
            "icon": "",
            "iconcss": "",
            "icontype": "",
            "label": "Industry’s longest cookie duration-365 days",
            "text": "",
            "textcss": "text-sm text-slate-300",
            "labelcss": "text-sm lg:text-lg p-2 text-left",
            "imagedata": {
                "src": process.env.PUBLIC_URL + "/icons/icon-cookie-duration.svg",
                "alt": "Icon",
                "width": "80px",
                "height": "80px",
                "style": "w-8  lg:w-12"
            }
        },
        {
            "wrapcss": "flex w-1/2 md:w-full xl:w-1/2 gap-1  lg:gap-2",
            "contentwrapcss": "flex flex-col",
            "icon": "",
            "iconcss": "",
            "icontype": "",
            "label": "24/7 live chat to turn referrals into revenue",
            "text": "",
            "textcss": "text-sm text-slate-300",
            "labelcss": "text-sm lg:text-lg p-2 text-left",
            "imagedata": {
                "src": process.env.PUBLIC_URL + "/icons/icon-24-7.svg",
                "alt": "Icon",
                "width": "80px",
                "height": "80px",
                "style": "w-8  lg:w-12"
            }
        },
    ],
    "inputfields": {
        "email": {
            "name": "Email",
            "wrapcss": "flex w-1/2 md:w-full xl:w-1/2",
            "label": "form-input bg-white w-full",
            "type": "email",
            "placeholder": "Enter Your Email",
            "labelcss": "text-slate-800",
            "inputfieldcss": "form-input rounded-lg border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
        },
        "password": {
            "name": "Password",
            "wrapcss": "flex w-1/2 md:w-full xl:w-1/2",
            "label": "",
            "type": "password",
            "placeholder": "Enter Your Password",
            "labelcss": "text-slate-800",
            "inputfieldcss": "form-input rounded-lg border-0 border-b-2 border-gray-200 focus:ring-0 focus:border-black"
        },
        "submitbutton": {
            "css": "mt-2 w-full text-center px-4 py-2 text-lg bg-primary text-white shadow rounded-lg ",
            "icon": "",
            "icontype": "",
            "iconcss": "",
            "buttontype": "submit",
            "label": "Log In"
        }

    }


}

export default data;