import * as OutlineHIcons from '@heroicons/react/outline';
import * as SolidHIcons from '@heroicons/react/solid';
import React from 'react';
import Image from '../Image/Image';

function IconTextBox(props) {
  const { textcss, labelcss, imagedata, wrapcss, icon, iconcss, icontype, label, text, contentwrapcss } = props.icontextboxdata;
  const icons = (icontype === "solid") ? { ...SolidHIcons } : { ...OutlineHIcons }
  const Icon = (icon === '') ? '' : icons[icon];
  return (
    <div className={wrapcss}>
      {(icon === '') ? '' : <Icon className={iconcss} />}
      {(imagedata === '') ? '' : <Image imagedata={imagedata} />}
      <div className={contentwrapcss}>
        <span className={labelcss}>{label}</span>
        <span className={textcss}>{text}</span>
      </div>
    </div>
  );
}
export default IconTextBox;